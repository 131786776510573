import { fixAssetUrl } from '@features/contentful/helpers/assetHelpers';
import { Itinerary, Review, Trip } from '@generated/graphql';
import { Product as SchemaProduct } from 'schema-dts';
import { Adapter } from './adapter';

export class ProductAdapter implements Adapter<Trip, SchemaProduct> {
    adapt(input: Trip): SchemaProduct {
        const itineraries = input.linkedFrom?.itineraryCollection?.items || [];
        const prices = itineraries.map(itinerary => itinerary.startingPrice);
        const numberOfReviews = itineraries.reduce(
            (previousValue, currentValue) =>
                previousValue + currentValue.numberOfReviews,
            0
        );
        const averageReviewRating =
            itineraries
                .map(
                    itinerary =>
                        itinerary.averageReviewRating *
                        itinerary.numberOfReviews
                )
                .reduce(
                    (previousValue, currentValue) =>
                        previousValue + currentValue,
                    0
                ) / numberOfReviews || 0;
        const identifier = this.contentfulIdToNumber(input.sys.id);
        return {
            '@type': 'Product',
            name: input.title,
            image: [
                `${fixAssetUrl(
                    input.featuredImage.url
                )}?w=1200&h=1200&q=85&fm=jpg`,
                `${fixAssetUrl(
                    input.featuredImage.url
                )}?w=1200&h=900&q=85&fm=jpg`,
                `${fixAssetUrl(
                    input.secondaryFeaturedImage.url
                )}?w=1920&h=1080&q=85&fm=jpg`,
            ],
            description: input.shortDescription,
            mpn: `${identifier}`,
            sku: `${identifier}`,
            review: [],
            brand: {
                '@type': 'Organization',
                name: 'Follow Alice',
                url: 'https://followalice.com',
            },
            aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: averageReviewRating,
                reviewCount: numberOfReviews,
            },
            offers: {
                '@type': 'AggregateOffer',
                offerCount: input.linkedFrom.itineraryCollection.total,
                lowPrice: Math.min(...prices),
                highPrice: Math.max(...prices),
                priceCurrency: 'USD',
                url: `/adventure-trips/${input.urlSlug}`,
            },
        };
    }

    adaptFromItinerary(input: Itinerary): SchemaProduct {
        const identifier = this.contentfulIdToNumber(input.sys.id);
        const priceValidUntil = new Date();
        priceValidUntil.setFullYear(priceValidUntil.getFullYear() + 1);

        return {
            '@type': 'Product',
            name: input.title,
            image: [
                `${fixAssetUrl(
                    input?.featuredImage?.url
                )}?w=1200&h=1200&q=85&fm=jpg`,
                `${fixAssetUrl(
                    input?.featuredImage?.url
                )}?w=1200&h=900&q=85&fm=jpg`,
                `${fixAssetUrl(
                    input?.featuredImage?.url
                )}?w=1920&h=1080&q=85&fm=jpg`,
            ],
            description: input.shortDescription,
            mpn: `${identifier}`,
            sku: `${identifier}`,
            review: [],
            brand: {
                '@type': 'Organization',
                name: 'Follow Alice',
                url: 'https://followalice.com',
            },
            offers: {
                '@type': 'Offer',
                priceCurrency: 'USD',
                price: input.startingPrice,
                availability: 'https://schema.org/InStock',
                priceValidUntil: priceValidUntil.toISOString(),
                url: `/adventure-trips/${input.trip.urlSlug}/${input.urlSlug}`,
            },
            aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: input.averageReviewRating,
                reviewCount: input.numberOfReviews,
            },
        };
    }

    adaptFromReview(review: Review): SchemaProduct {
        const identifier = this.contentfulIdToNumber(review.itinerary?.sys?.id);
        const priceValidUntil = new Date();
        priceValidUntil.setFullYear(priceValidUntil.getFullYear() + 1);
        return {
            '@type': 'Product',
            name: review.itinerary?.title,
            mpn: `${identifier}`,
            sku: `${identifier}`,
            brand: {
                '@type': 'Organization',
                name: 'Follow Alice',
                url: 'https://followalice.com',
            },
            offers: {
                '@type': 'Offer',
                priceCurrency: 'USD',
                price: review?.itinerary?.startingPrice,
                availability: 'https://schema.org/InStock',
                priceValidUntil: priceValidUntil.toISOString(),
                url: `/adventure-trips/${review?.itinerary?.trip?.urlSlug}/${review?.itinerary?.urlSlug}`,
            },
        };
    }

    private contentfulIdToNumber(id: string): number {
        let hash = 0,
            chr;
        if (id.length === 0) return hash;
        for (let i = 0; i < id.length; i++) {
            chr = id.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return Math.abs(hash);
    }
}
